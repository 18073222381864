import { useState } from 'react'
import { ethers } from 'ethers'
import NFT from '../utils/abi.json'
import { contractAddress,MintPriceInEther,Supply, /*gasLimitPerNft*/ } from '../config'
const MintLocked = () => {
    const [counter, setCounter] = useState('')
    
    const { ethereum } = window
    if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum)
        const signer = provider.getSigner()
        const nftContract = new ethers.Contract(
          contractAddress,
          NFT.abi,
          signer
        )
    
    
    nftContract.totalSupply()
    .then((data) => { 
    setCounter(String(parseInt(data._hex)));
    })
    }


    return ( 
        <>
        <div className="neoh_fn_nft_cats_small">
          <ul>
          <li>
              <div className="item">
                <h4 className="parent_category_small">Price</h4>
                <h3 className="child_category_small" title="{MintPriceInEther} Ξth">{MintPriceInEther} Ξth</h3>
              </div>
            </li>
            <li>
              <div className="item">
                <h4 className="parent_category_small">Total Minted</h4>
                <h3 className="child_category_small" title="{counter} / {Supply}">{counter} / {Supply}</h3>
              </div>
            </li>
          </ul>
        </div>
        <div className="buttons" ><button
        type="button"
        disabled=""
        className="neoh_fn_button only_text"
    >Mint is not enabled</button></div>
    </>
     );
}
 
export default MintLocked;