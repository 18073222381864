export const contractAddress = "0x32aB3739E5Bd3CE186d39d759175CdfCC358a044" //[0xE3782DB6b6F75e07c2b76a537fFaa8CAcCd2bc83, 0x32aB3739E5Bd3CE186d39d759175CdfCC358a044]
export const activeNetwork = {
    name: "Mainnet Network", // [Goerli Network, Mainnet Network]
    chainId: "0x1",  // [0x5, 0x1]
}
export const activePhase = "Closed" // [Mint, Closed]
export const MintPriceInEther = 0.0069
export const VoucherMintPriceInEther = 0.0069
export const gasLimitPerNft = 258000
//export const mintPhase = "allowlist"
export const mintPhase = "public" // [public, closed]
export const signerAddress = "0x6f0a1e27ce4c832ec2734ab695ec0bd1ef53b0d9" //LowerCase
export const Supply = "69"